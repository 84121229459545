import {Api, SUBAPI_BASE} from '.'

const CURRENT_SUBAPI_BASE = `${SUBAPI_BASE}/rating`

export const ratingApi = Api.injectEndpoints({
  endpoints: builder => ({
    getRatingCheck: builder.query({
      query: ({itemId = null, itemType = ''}) => ({
        url: `${CURRENT_SUBAPI_BASE}/check?itemId=${itemId}&itemType=${itemType}`
      }),
      providesTags: (result, error, {itemId, itemType}) => [
        {type: 'Rating', id: `check-${itemType}-${itemId}`}
      ]
    }),
    getRatingByItemTypeAndId: builder.query({
      query: ({itemId, itemType, limit, offset}) => ({
        url: `${CURRENT_SUBAPI_BASE}/get-for-entity/${itemId}?entityType=${itemType}&limit=${limit}&offset=${offset}`
      })
    }),
    postPerformRate: builder.mutation({
      query: body => ({
        url: `${CURRENT_SUBAPI_BASE}/`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, {itemType, itemId}) => [
        {type: 'Rating', id: `${itemType}-${itemId}`}
      ]
    }),
    updateRating: builder.mutation({
      query: ({id, ...body}) => ({
        url: `${CURRENT_SUBAPI_BASE}/update/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, {itemType, itemId}) => [
        {type: 'Rating', id: `${itemType}-${itemId}`}
      ]
    }),
    reportRating: builder.mutation({
      query: ({id}) => ({
        url: `${CURRENT_SUBAPI_BASE}/report/${id}`,
        method: 'POST'
      })
    }),
    deleteRating: builder.mutation({
      query: ({id}) => ({
        url: `${CURRENT_SUBAPI_BASE}/delete/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, {itemType, itemId}) => [
        {type: 'Rating', id: `${itemType}-${itemId}`}
      ]
    })
  })
})

export const {
  useGetRatingCheckQuery,
  useGetRatingByItemTypeAndIdQuery,
  useLazyGetRatingByItemTypeAndIdQuery,
  usePostPerformRateMutation,
  useUpdateRatingMutation,
  useReportRatingMutation,
  useDeleteRatingMutation
} = ratingApi
