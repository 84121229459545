import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'

import {prepareBaseQuery} from '../'

import {userApi} from './user'

const DEFAULT_ATTRACTION_LIMIT = 10

export const attractionApi = createApi({
  reducerPath: 'attractionApi',
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery('attraction'),
  tagTypes: ['Attraction'],
  endpoints: builder => ({
    getByHost: builder.query({
      query: () => ({url: '/get-by-host'}),
      transformResponse: response => response.attractions,
      providesTags: result =>
        result
          ? [...result.map(({id}) => ({type: 'Attraction', id})), {type: 'Attraction', id: 'LIST'}]
          : [{type: 'Attraction', id: 'LIST'}]
    }),
    getBySlugForEdit: builder.query({
      query: ({slug}) => ({url: `/edit/${slug}`}),
      transformResponse: response => response.attraction,
      providesTags: (result, error, {slug}) => [{type: 'Attraction', id: slug}]
    }),
    createAttraction: builder.mutation({
      query: body => {
        const formData = new window.FormData()
        formData.append('data', JSON.stringify(body))

        return {
          url: '/create',
          method: 'POST',
          body: formData
        }
      },
      invalidatesTags: () => [{type: 'Attraction', id: 'LIST'}],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    }),
    updateAttraction: builder.mutation({
      query: ({id, ...body}) => {
        const formData = new window.FormData()
        formData.append('data', JSON.stringify({id, ...body}))

        return {
          url: `/${id}`,
          method: 'PUT',
          body: formData
        }
      },
      invalidatesTags: (result, error, {id}) => [{type: 'Attraction', id}]
    }),
    deleteAttraction: builder.mutation({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{type: 'Attraction', id}],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    }),
    getByLocationAndCategories: builder.query({
      query: (args = {}) => ({
        url: `/by-location-and-categories?limit=${
          args?.limit ? args.limit : DEFAULT_ATTRACTION_LIMIT
        }&offset=${args?.offset ? args.offset : 0}${
          args?.location?.countryId ? `&countryId=${args.location.countryId}` : ''
        }${
          args?.location?.regionIds && args.location.regionIds.length > 0
            ? `&regionIds=${args.location.regionIds.join(',')}`
            : ''
        }${
          args?.location?.cityIds && args.location.cityIds.length > 0
            ? `&cityIds=${args.location.cityIds.join(',')}`
            : ''
        }${
          args?.location?.others && args.location.others.length > 0
            ? `&others=${args.location.others.join(',')}`
            : ''
        }${
          args?.categories && args.categories.length > 0
            ? `&categoryIds=${args.categories.join(',')}`
            : ''
        }${args?.languageId ? `&lang=${args.languageId}` : ''}`
      })
    })
  })
})

export const {
  useGetByHostQuery,
  useCreateAttractionMutation,
  useUpdateAttractionMutation,
  useGetBySlugForEditQuery,
  useDeleteAttractionMutation,
  useGetByLocationAndCategoriesQuery,
  useLazyGetByLocationAndCategoriesQuery,
  util: {getRunningOperationPromises}
} = attractionApi

export const {getByHost, getBySlugForEdit, getByLocationAndCategories} = attractionApi.endpoints
