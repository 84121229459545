import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'

import {prepareBaseQuery} from '../'

export const destinationApi = createApi({
  reducerPath: 'destinationApi',
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery('destinations'),
  tagTypes: ['Destination'],
  endpoints: builder => ({
    getAllExploreDestinations: builder.query({
      query: (args = {}) => ({
        url: `/explore?limit=${args?.limit}&offset=${args?.offset}`
      })
    })
  })
})

export const {
  useGetAllExploreDestinationsQuery,
  useLazyGetAllExploreDestinationsQuery,
  util: {getRunningOperationPromises}
} = destinationApi

export const {getAllExploreDestinations} = destinationApi.endpoints
