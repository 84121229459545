import {Api, SUBAPI_BASE} from '.'

const CURRENT_SUBAPI_BASE = `${SUBAPI_BASE}/payment-methods`

export const paymentMethodsApi = Api.injectEndpoints({
  endpoints: builder => ({
    getCardsForUser: builder.query({
      query: () => ({url: `${CURRENT_SUBAPI_BASE}/card/mine`}),
      transformResponse: response =>
        response.cards.map(card => ({
          id: card.id,
          type: card.funding,
          number: card.lastFourDigits
        })),
      providesTags: () => [{type: 'PaymentMethods', id: 'USER_CARDS'}]
    }),
    postCardForUser: builder.mutation({
      query: body => ({url: `${CURRENT_SUBAPI_BASE}/card/attach`, method: 'POST', body}),
      invalidatesTags: () => [{type: 'PaymentMethods', id: 'USER_CARDS'}]
    }),
    deleteCardForUser: builder.mutation({
      query: id => ({
        url: `${CURRENT_SUBAPI_BASE}/card/remove?sourceId=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [
        {type: 'PaymentMethods', id: 'USER_CARDS'},
        {type: 'Subscriptions', id: 'MY_PLAN'}
      ]
    })
  }),
  overrideExisting: false
})

export const {
  useGetCardsForUserQuery,
  useDeleteCardForUserMutation,
  usePostCardForUserMutation
} = paymentMethodsApi
