import {createApi} from '@reduxjs/toolkit/query/react'

import {prepareBaseQuery} from '../'

const DEFAULT_SEARCH_LIMIT = 10
const DEFAULT_EXPERIENCES_SEARCH_LIMIT = 5

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: prepareBaseQuery('search'),
  tagTypes: ['Search'],
  endpoints: builder => ({
    getMediaBundles: builder.query({
      query: ({phrase, limit = DEFAULT_SEARCH_LIMIT, offset = 0}) => ({
        url: `/guides?phrase=${phrase}&limit=${limit}&offset=${offset}`
      }),
      transformResponse: response => response
    }),
    getExperiences: builder.query({
      query: ({phrase, limit = DEFAULT_EXPERIENCES_SEARCH_LIMIT, offset = 0}) => ({
        url: `/experiences?phrase=${phrase}&limit=${limit}&offset=${offset}`
      }),
      transformResponse: response => response
    }),
    getHomePage: builder.query({
      query: ({phrase}) => ({
        url: `/home-page?phrase=${phrase}`
      }),
      transformResponse: response => response
    })
  })
})

export const {
  useLazyGetHomePageQuery,
  useLazyGetMediaBundlesQuery,
  useLazyGetExperiencesQuery
} = searchApi
