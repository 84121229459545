import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'

import {prepareBaseQuery} from '../'

export const locationApi = createApi({
  reducerPath: 'locationApi',
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery('location'),
  tagTypes: ['Location'],
  endpoints: builder => ({
    getDetailedCountries: builder.query({
      query: () => ({
        url: '/detailed-countries'
      })
    })
  })
})

export const {
  useGetDetailedCountriesQuery,
  util: {getRunningOperationPromises}
} = locationApi

export const {getDetailedCountries} = locationApi.endpoints
