import ActivatorButton from 'assets/icons/chatbot-button.svg'

import * as S from './Styled'

const ChatbotActivator = ({shouldShowOnMobile, toggleChat = () => {}}) => {
  return (
    <S.ActivatorButtonWrapper shouldShowOnMobile={shouldShowOnMobile} onClick={toggleChat}>
      <ActivatorButton />
    </S.ActivatorButtonWrapper>
  )
}

export default ChatbotActivator
