import {getAsync} from './cpBackendApi'

const PROMOTION_LIMIT = 50

export const getTour = async (
  id = undefined,
  slug = undefined,
  languageId,
  token,
  options = {}
) => {
  const sendAllObjectImages =
    options.objectImages && options.objectImages === true ? '&getAllObjectImages=true' : ''
  const response = await getAsync(
    `media-bundles/bundle/${id}/preview?slug=${slug}&lang=${languageId}${sendAllObjectImages}`,
    token
  )
  return response.data
}

export const getRelatedTours = async (id = undefined, languageId) => {
  const response = await getAsync(`media-bundles/bundle/${id}/related?lang=${languageId}`, '')
  return response.data
}

export const getDonationTour = async (languageId, limit = PROMOTION_LIMIT, offset = 0) => {
  const response = await getAsync(
    'media-bundles/donation-promotion?limit=' + limit + '&offset=' + offset + '&lang=' + languageId
  )
  return response.data
}

export const getArtistsPromotionTours = async (languageId, limit = PROMOTION_LIMIT, offset = 0) => {
  const response = await getAsync(
    'media-bundles/artists-promotion?limit=' + limit + '&offset=' + offset + '&lang=' + languageId
  )
  return response.data
}

export const getPromotedTours = async languageId => {
  const response = await getAsync('media-bundles/promotion?lang=' + languageId)
  return response.data
}
