import {useEffect, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'

import CloseButton from 'assets/icons/chatbot-minus-close.svg'
import ChatbotSmile from 'assets/icons/emoticons/smile-chatbot.svg'

import WaitingMessageDots from './components/WaitingMessageDots'

import * as S from './Styled'

const ChatWindow = ({
  messages,
  askQuestion = () => {},
  isVisible,
  closeChat = () => {},
  shouldShowLoadingDots
}) => {
  const {t} = useTranslation()
  const messagesEndRef = useRef()
  const inputRef = useRef()
  const [chatMessage, setChatMessage] = useState('')

  const sendMessage = e => {
    e.preventDefault()

    if (chatMessage !== '') {
      askQuestion(chatMessage)
      setChatMessage('')
    }
  }

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView()
  }, [messages])

  useEffect(() => {
    inputRef.current?.focus()
  }, [isVisible])

  useEffect(() => {
    if (!shouldShowLoadingDots) {
      inputRef.current?.focus()
    }
  }, [shouldShowLoadingDots])

  return (
    <S.Wrapper isVisible={isVisible}>
      <S.Header>
        <S.HeaderLeftContent>
          <ChatbotSmile />
          <S.HeaderContentWrapper>{t('CHATBOT_HEADER_TITLE')} </S.HeaderContentWrapper>
        </S.HeaderLeftContent>
        <S.CloseButtonWrapper>
          <CloseButton onClick={closeChat} />
        </S.CloseButtonWrapper>
      </S.Header>
      <S.MessageContainer>
        {messages.map((message, index) => {
          return (
            <>
              <S.MessageItemWrapper key={index} setRight={message.type === 'user'}>
                <S.MessageItem setRight={message.type === 'user'}>{message.text}</S.MessageItem>
              </S.MessageItemWrapper>
            </>
          )
        })}
        <WaitingMessageDots isVisible={shouldShowLoadingDots} />
        <div ref={messagesEndRef} />
      </S.MessageContainer>
      <S.InputForm>
        <S.InputContainer>
          <S.InputMessage
            ref={inputRef}
            onChange={e => setChatMessage(e.target.value)}
            value={chatMessage}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                sendMessage(e)
              }
            }}
            disabled={shouldShowLoadingDots}
          />
          <S.SendButtonWrapper>
            <S.InputSendButton
              onClick={e => {
                sendMessage(e)
                inputRef.current.focus()
              }}
            />
          </S.SendButtonWrapper>
        </S.InputContainer>
      </S.InputForm>
    </S.Wrapper>
  )
}

export default ChatWindow
