import {createApi} from '@reduxjs/toolkit/query/react'

import {prepareBaseQuery} from '../'

export const languageApi = createApi({
  reducerPath: 'languageApi',
  baseQuery: prepareBaseQuery('local'),
  tagTypes: ['Language'],
  endpoints: builder => ({
    getAvailableLanguages: builder.query({
      query: phrase => ({url: phrase ? `/languages?phrase=${phrase}` : '/languages'}),
      transformResponse: response =>
        response.languages.map(lang => ({id: lang.id, name: lang.nameInEnglish}))
    })
  })
})

export const {useGetAvailableLanguagesQuery, useLazyGetAvailableLanguagesQuery} = languageApi
