import {createApi} from '@reduxjs/toolkit/query/react'

import {prepareBaseQuery} from '../'

export const entityApi = createApi({
  reducerPath: 'entityApi',
  baseQuery: prepareBaseQuery('ability'),
  tagTypes: ['EntityCheck'],
  endpoints: builder => ({
    checkIfUserCanEditEntity: builder.query({
      query: ({entity, entityId}) => ({
        url: `check?action=update&entity_type=${entity}&entity_id=${entityId}`
      }),
      transformResponse: response => response,
      providesTags: (result, error, {entityId, entity}) => [
        {type: 'EntityCheck', id: `${entity}-${entityId}`}
      ]
    })
  })
})

export const {useLazyCheckIfUserCanEditEntityQuery} = entityApi
