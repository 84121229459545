import {deleteReq, getAsync, post, put, putAsync} from './cpBackendApi'

export const updateCoverImage = (data, onSuccess, onError) => {
  put('/user/my-cover', data, onSuccess, onError)
}

export const updateAvatarImage = (data, onSuccess, onError) => {
  put('/user/my-image', data, onSuccess, onError)
}

export const signUp = (data, onSuccess, onError) => {
  post('/auth/register', data, onSuccess, onError)
}

export const activateAccount = async token => {
  await getAsync(`/auth/activate?token=${token}`, '')
}

export const resendActivation = (formData, onSuccess, onError) => {
  post('/auth/resend-activation', formData, onSuccess, onError)
}

export const logIn = (type, formData, onSuccess, onError) => {
  let url = undefined
  if (type === 'GOOGLE') {
    url = '/auth/login-google'
  } else if (type === 'FACEBOOK') {
    url = '/auth/login-facebook'
  } else {
    url = '/auth/login'
  }
  post(url, formData, onSuccess, onError)
}

export const getMinifiedProfile = async token => {
  const response = await getAsync('user/minified-profile', token)
  return response.data && response.data.user
}

export const updateProfileData = data => {
  return putAsync('/user/my-profile', data)
}

export const getProfile = async token => {
  const response = await getAsync('user/my-profile', token)
  return response.data
}

// eslint-disable-next-line
export const getPurchaseHistory = async token => {
  const response = await getAsync('user/my-orders', token)
  return response.data && response.data.data
}

export const getCity = async phrase => {
  const response = await getAsync('local/cities?phrase=' + phrase)
  return response.data && response.data.cities
}

export const getCountries = async (langId = 'en') => {
  const response = await getAsync('local/countries?lang=' + langId)
  return response.data && response.data.countries
}

export const deleteUser = (onSuccess, onError) => {
  deleteReq('user/my-profile', onSuccess, onError)
}
