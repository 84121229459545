import React, {useEffect, useState} from 'react'

import {useRouter} from 'next/router'
import {useTranslation} from 'react-i18next'

import withAppContext from 'common/HOCs/withAppContext'
import useUserContext from 'common/hooks/useUserContext'
import {postAsync} from 'services/cpBackendApi'

import ChatbotActivator from './components/ActivatorButton'
import ChatWindow from './components/ChatWindow'

const STATUS_CODE_OK = 200
const RESTRICTED_PAGES = {
  TOURPAGE: 'tourpage',
  CREATEPAGE: 'create',
  EDITPAGE: 'edit'
}

const Chatbot = ({appContext}) => {
  const {t} = useTranslation()
  const {user} = useUserContext()
  const router = useRouter()
  const {showChatBot} = appContext

  const userGreeting = user.firstName ? user.firstName + '!' : ''
  const [isChatVisible, setIsChatVisible] = useState(false)
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false)
  const [messages, setMessages] = useState([
    {
      type: 'chatbot',
      text: t('CHATBOT_GREETING', {USER_NAME: userGreeting})
    }
  ])

  const shouldShowOnTourPage = !router.route.includes(RESTRICTED_PAGES.TOURPAGE)

  const openChat = isOpen => {
    setIsChatVisible(isOpen)
  }

  const toggleChat = () => {
    setIsChatVisible(!isChatVisible)
  }

  const askQuestion = async question => {
    setMessages(oldMessages => [
      ...oldMessages,
      {
        type: 'user',
        text: question
      }
    ])

    setIsWaitingForResponse(true)
    const response = await postAsync('chatbot/chatbot-ask', {question})
    setIsWaitingForResponse(false)

    if (response.status === STATUS_CODE_OK) {
      setMessages(oldMessages => [
        ...oldMessages,
        {
          type: 'chatbot',
          text: response.data.message
        }
      ])
    }
  }

  useEffect(() => {
    if (messages.length === 1) {
      setMessages([
        {
          type: 'chatbot',
          text: t('CHATBOT_GREETING', {USER_NAME: userGreeting})
        }
      ])
    }
  }, [user.firstName])

  return (
    <>
      {showChatBot && shouldShowOnTourPage && (
        <>
          <ChatWindow
            messages={messages}
            askQuestion={askQuestion}
            isVisible={isChatVisible}
            closeChat={() => openChat(false)}
            shouldShowLoadingDots={isWaitingForResponse}
          />
          <ChatbotActivator shouldShowOnMobile={!isChatVisible} toggleChat={toggleChat} />
        </>
      )}
    </>
  )
}

export default withAppContext(Chatbot)
