import {getAsync, postAsync} from './cpPaymentApi'

export const getCheckObject = async id => {
  const response = await getAsync(`check-object/${id}`)
  return response.data
}

export const postNewGrantedPayment = async (data, token) => {
  const response = await postAsync('payment/granted/new', data, token)
  return response.data
}
