import {configureStore} from '@reduxjs/toolkit'
import {createWrapper} from 'next-redux-wrapper'
import {wrapMakeStore} from 'next-redux-cookie-wrapper'
import rootMiddleware from './middlewares'
import rootReducer from './reducers'
import nextCookie from 'next-cookies'
import {resolveCurrentLanguage} from 'common/helpers/language'

export const makeStore = context => {
  let token = null
  let lang = resolveCurrentLanguage()

  if (context) {
    token = nextCookie(context).token
    lang = resolveCurrentLanguage(context.req)
  }

  return configureStore({
    reducer: rootReducer,
    middleware: rootMiddleware({token, lang})
  })
}

const wrappedMakeStore = wrapMakeStore(makeStore)

export const wrapper = createWrapper(wrappedMakeStore, {debug: !__PRODUCTION__})
