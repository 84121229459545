import config from 'config'
import axios from 'axios'

const getHeader = token => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token || '',
      'x-cp-client': 'cp-web-client'
    }
  }
}

const CPPaymentApi = axios.create({
  baseURL: config.CP_PAYMENT_BASE
})

export const getAsync = async (url, token = '') => {
  return await CPPaymentApi.get(url, getHeader(token))
}

export const postAsync = async (url, data, token = '') => {
  return await CPPaymentApi.post(url, data, getHeader(token))
}
