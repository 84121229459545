import {createApi} from '@reduxjs/toolkit/query/react'
import {prepareBaseQuery} from '../'
import {userApi} from './user'

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: prepareBaseQuery('media'),
  tagTypes: ['Image', 'Audio'],
  endpoints: builder => ({
    postStoreImage: builder.mutation({
      query: ({ownerHostId, ...body}) => {
        const formData = new window.FormData()
        formData.append('data', JSON.stringify(body))
        formData.append('image', body.imageBlob)

        return {
          url: '/images',
          headers: {'owner-host-id': ownerHostId},
          method: 'POST',
          body: formData
        }
      },
      invalidatesTags: () => [{type: 'Image', id: 'LIST'}],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    }),
    putUpdateImage: builder.mutation({
      query: ({ownerHostId, ...body}) => {
        const formData = new window.FormData()
        formData.append('data', JSON.stringify(body))
        formData.append('image', body.blob)

        return {
          url: `/images/edit/${body.id}`,
          headers: {'owner-host-id': ownerHostId},
          method: 'PUT',
          body: formData
        }
      },
      invalidatesTags: (result, error, {id}) => [
        {type: 'Image', id},
        {type: 'Image', id: 'LIST'}
      ],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    }),
    deleteImage: builder.mutation({
      query: ({ownerHostId, ...body}) => ({
        url: `/images/delete/${body.id}`,
        headers: {'owner-host-id': ownerHostId},
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, {id}) => [
        {type: 'Image', id},
        {type: 'Image', id: 'LIST'}
      ],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    })
  })
})

export const {
  usePostStoreImageMutation,
  usePutUpdateImageMutation,
  useDeleteImageMutation
} = mediaApi
