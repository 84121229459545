import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'

import {prepareBaseQuery} from '../'

import {userApi} from './user'

const LIMIT_20 = 20
const DEFAULT_OFFSET = 0

export const mediaBundleApi = createApi({
  reducerPath: 'mediaBundleApi',
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery('media-bundles'),
  tagTypes: ['MediaBundle', 'MediaBundleLanguages'],
  endpoints: builder => ({
    getForEditMediaBundle: builder.query({
      query: id => ({url: `/edit/${id}`}),
      transformResponse: response => response.mediaBundle,
      providesTags: (result, error, id) => [{type: 'MediaBundle', id}]
    }),
    getPreviewBySlug: builder.query({
      query: ({slug, languageId}) => ({
        url: `/preview/${slug}?lang=${'en'}`
      }),
      transformResponse: response => response.mediaBundle,
      providesTags: (result, error, slug) => [{type: 'MediaBundle', id: slug}]
    }),
    getBundleAvailableLanguages: builder.query({
      query: id => ({url: `/bundle/${id}/available-languages`}),
      transformResponse: response => response.languages,
      providesTags: (result, error, slug) => [{type: 'MediaBundleLanguages', id: slug}]
    }),
    getHostBundles: builder.query({
      query: ({limit, offset}) => ({
        url: `/my-guides?limit=${limit}&offset=${offset}`
      }),
      transformResponse: response => response,
      providesTags: result =>
        result
          ? [
              ...result.guides.map(({id}) => ({type: 'MediaBundle', id})),
              {type: 'MediaBundle', id: 'LIST'}
            ]
          : [{type: 'MediaBundle', id: 'LIST'}]
    }),
    getTranslationsForBundle: builder.query({
      query: id => ({url: `/translations/${id}`})
    }),
    getRelatedGuides: builder.query({
      query: id => ({url: `/bundle/${id}/related`}),
      transformResponse: response => response.mediaBundles
    }),
    getTour: builder.query({
      query: ({slug, languageId}) => ({
        url: `/content/${slug}?lang=${languageId}`
      }),
      transformResponse: response => response.mediaBundle,
      providesTags: (result, error, {slug}) => [{type: 'MediaBundle', id: slug}]
    }),
    getByLocationAndCategories: builder.query({
      query: (args = {}) => ({
        url: `/by-location-and-categories/?limit=${args?.limit ? args?.limit : LIMIT_20}&offset=${
          args?.offset ? args?.offset : DEFAULT_OFFSET
        }${args?.countryId ? `&country_id=${args?.countryId}` : ''}${
          args?.regionId ? `&region_id=${args?.regionId}` : ''
        }${args?.cityId ? `&city_id=${args?.cityId}` : ''}${
          args?.categories ? `&categories=${args?.categories}` : ''
        }${args?.languageId ? `&lang=${'en'}` : ''}`
      }),
      transformResponse: response => ({total: response.total, guides: response.guides}),
      providesTags: (
        result,
        error,
        {cityId = null, regiondId = null, countryId = null, categories = []}
      ) => [
        {type: 'MediaBundles', id: `${cityId}-${regiondId}-${countryId}-${categories.toString()}`}
      ]
    }),
    createMediaBundle: builder.mutation({
      query: body => ({
        url: '/create',
        method: 'POST',
        body
      }),
      transformResponse: response => response.mediaBundle,
      invalidatesTags: [{type: 'MediaBundle', id: 'LIST'}],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    }),
    updateBundleTranslation: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/translations/${id}`,
        method: 'PUT',
        body
      })
    }),
    deleteBundleTranslation: builder.mutation({
      query: ({guideId, languageId}) => ({
        url: `/translations/${guideId}/${languageId}`,
        method: 'DELETE'
      })
    }),
    deleteMediaBundle: builder.mutation({
      query: ({id}) => ({
        url: `/bundle/delete/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, {id, slug}) => [
        {type: 'MediaBundle', id},
        {type: 'MediaBundle', id: slug},
        {type: 'MediaBundle', id: 'LIST'}
      ],
      async onCacheEntryAdded(arg, {dispatch, cacheDataLoaded}) {
        await cacheDataLoaded
        dispatch(userApi.util.invalidateTags([{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]))
      }
    }),
    updateMediaBundle: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/update/${id}`,
        method: 'PUT',
        body
      }),
      transformResponse: response => response.mediaBundle,
      invalidatesTags: (result, error, {id, slug}) => [
        {type: 'MediaBundle', id},
        {type: 'MediaBundle', id: slug},
        {type: 'MediaBundle', id: 'LIST'}
      ]
    }),
    spendCode: builder.mutation({
      query: ({code}) => ({
        url: '/check-code/',
        method: 'POST',
        body: {code}
      }),
      transformResponse: response => response.mediaBundleSlugs
    })
  })
})

export const {
  useCreateMediaBundleMutation,
  useUpdateMediaBundleMutation,
  useGetForEditMediaBundleQuery,
  useGetBundleAvailableLanguagesQuery,
  useLazyGetBundleAvailableLanguagesQuery,
  useGetPreviewBySlugQuery,
  useGetHostBundlesQuery,
  useDeleteMediaBundleMutation,
  useGetTranslationsForBundleQuery,
  useUpdateBundleTranslationMutation,
  useDeleteBundleTranslationMutation,
  useSpendCodeMutation,
  useGetTourQuery,
  useLazyGetTourQuery,
  useLazyGetHostBundlesQuery,
  useGetRelatedGuidesQuery,
  useGetByLocationAndCategoriesQuery,
  // This an export for SSR
  util: {getRunningOperationPromises}
} = mediaBundleApi

// Export endpoints for use in SSR
export const {
  getForEditMediaBundle,
  getPreviewBySlug,
  getTour,
  getHostBundles,
  getByLocationAndCategories
} = mediaBundleApi.endpoints
