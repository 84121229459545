/**
 * Customized App component that enables:
 *  - next-i18next
 * @flow
 */
import React from 'react'

import '@babel/polyfill'
import {MuiThemeProvider} from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import cookie from 'js-cookie'
import nextCookie from 'next-cookies'
import App from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import JssProvider from 'react-jss/lib/JssProvider'
import {ThemeProvider} from 'styled-components'

// Providers
import CPAppContext from 'common/context/CPApp/CPAppContext'
import CPCartProvider from 'common/context/CPCart/CPCartProvider'
import CPGeolocationProvider from 'common/context/CPGeolocation/CPGeolocationProvider'
import CPSocketProvider from 'common/context/CPSocket/CPSocketProvider'
import CPUserProvider from 'common/context/CPUser/CPUserProvider'
import GlobalStyles from 'common/helpers/GlobalStyles'
import theme from 'common/helpers/theme'
import Chatbot from 'components/Chatbot'
import config from 'config'
import {wrapper} from 'lib/store'
import {captureException} from 'services/errorService'
import getPageContext from 'services/getPageContext'
import GrantByCodeService from 'services/grantByCodeService'
import {updateSessionStorage} from 'services/historyManager'
import {getMinifiedProfile} from 'services/userService'

import {appWithTranslation} from '../i18n'

if (__PRODUCTION__) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: 'production'
  })
}

class CPApp extends App {
  static getInitialProps = wrapper.getInitialAppProps(() => async ({Component, ctx}) => {
    const {token} = nextCookie(ctx)
    let user = {isLogged: false}

    if (token) {
      try {
        user = await getMinifiedProfile(token)
      } catch (e) {
        // User is already set properly.
      }
    }

    const userAgent =
      typeof window === 'undefined' ? ctx.req.headers['user-agent'] : navigator.userAgent

    return {
      pageProps: {
        token,
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
        namespacesRequired: ['common'],
        user: {
          isLogged: !!token,
          ...user
        },
        userAgent
      }
    }
  })

  state = {
    [CPAppContext.CONTEXT_ID]: {...CPAppContext.stateData}
  }

  setContextData = (contextID, mrData) => {
    this.setState({[contextID]: {...this.state[contextID], ...mrData}})
  }

  invalidateAuthTokens() {
    window.localStorage.removeItem('login')
    cookie.remove('token')
    window.localStorage.setItem('logout', Date.now())
    window.localStorage.setItem('invalidateTokens', false)
    // eslint-disable-next-line no-undef
    location.reload()
  }

  constructor() {
    super()
    this.pageContext = getPageContext()
  }

  componentDidUpdate(prevProps) {
    updateSessionStorage(Router)

    if (window.localStorage.getItem('version') != config.VERSION) {
      window.localStorage.setItem('version', config.VERSION)
      window.localStorage.setItem('invalidateTokens', config.INVALIDATE_AUTH_TOKENS)
    }

    if (window.localStorage.getItem('invalidateTokens') == 'true') {
      this.invalidateAuthTokens()
    }

    if ('isBanned' in this.props.pageProps.user && this.props.pageProps.user.isBanned) {
      this.invalidateAuthTokens()
    } else {
      if (
        'user' in prevProps.pageProps &&
        'isLogged' in prevProps.pageProps.user &&
        !prevProps.pageProps.user.isLogged &&
        'user' in this.props.pageProps &&
        'isLogged' in this.props.pageProps.user &&
        this.props.pageProps.user.isLogged
      ) {
        // User loged in
        this.grantItems()
      }
    }
  }

  componentDidMount() {
    Router.router.events.on('routeChangeStart', () => {
      NProgress.start()
      // Close search if it is opened.
      this.setContextData(CPAppContext.CONTEXT_ID, {showSearch: false})
    })
    Router.router.events.on('routeChangeComplete', () => NProgress.done())
    Router.router.events.on('routeChangeError', () => NProgress.done())

    // Remove the server-side injected CSS.
    // eslint-disable-next-line
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    this.grantItems()
  }

  componentDidCatch(error, errorInfo) {
    if (__PRODUCTION__) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key])
        })
        captureException(error)
      })
    }
    super.componentDidCatch(error, errorInfo)
  }

  setStateData = (contextID, object) => {
    const result = {
      setContextData: data => this.setContextData(contextID, data)
    }
    const hasOwnProperty = Object.prototype.hasOwnProperty
    for (let property in object) {
      if (hasOwnProperty.call(object, property)) {
        result[property] = this.state[contextID][property]
      }
    }
    return result
  }

  grantItems = () => {
    const grantByCodeService = new GrantByCodeService(this.props)
    if (grantByCodeService.unusedGrantCode) {
      grantByCodeService.useCode()
    }
  }

  render() {
    const {Component, pageProps} = this.props

    return (
      <CPAppContext.AppProvider
        value={{
          ...this.setStateData(CPAppContext.CONTEXT_ID, CPAppContext.stateData),
          userAgent: pageProps.userAgent
        }}
      >
        <JssProvider
          registry={this.pageContext.sheetsRegistry}
          generateClassName={this.pageContext.generateClassName}
        >
          <CPSocketProvider>
            <MuiThemeProvider theme={this.pageContext.theme}>
              <ThemeProvider theme={theme}>
                <CPGeolocationProvider>
                  <CPCartProvider>
                    <CPUserProvider user={pageProps.user}>
                      <GlobalStyles />
                      <Chatbot />
                      <Component pageContext={this.pageContext} {...pageProps} />
                    </CPUserProvider>
                  </CPCartProvider>
                </CPGeolocationProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </CPSocketProvider>
        </JssProvider>
        <style jsx global>{`
          .page-transition-enter {
            opacity: 0;
          }
          .page-transition-enter-active {
            opacity: 1;
            transition: opacity 300ms;
          }
          .page-transition-exit {
            opacity: 1;
          }
          .page-transition-exit-active {
            opacity: 0;
            transition: opacity 300ms;
          }
        `}</style>
      </CPAppContext.AppProvider>
    )
  }
}

export default appWithTranslation(wrapper.withRedux(CPApp))
