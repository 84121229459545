import styled from 'styled-components'

import SendButton from 'assets/icons/send.svg'

export const Wrapper = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;

  ${props => props.theme.mediaQuery.md} {
    width: 375px;
    right: 32px;
    bottom: 90px;
    top: unset;
    left: unset;
    height: auto;
    border: 1px solid ${props => props.theme.colors.secondary2};
    border-radius: 12px;
  }
`

export const Header = styled.div`
  background: ${props => props.theme.colors.primary1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 16px;

  ${props => props.theme.mediaQuery.md} {
    border-radius: 12px 12px 0 0;
  }
`

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderContentWrapper = styled.div`
  color: white;
  font-weight: 700;
  line-height: 1.3;
  margin-left: 8px;
`

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
`

export const MessageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: white;

  ${props => props.theme.mediaQuery.md} {
    height: 465px;
    overflow-y: auto;
    flex: auto;
  }

  ${props => props.theme.mediaQuery.lg} {
    height: 485px;
  }

  &::-webkit-scrollbar {
    background: ${props => props.theme.colors.secondary2};
    border-radius: 24px;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.secondary5};
    border-radius: 24px;
  }

  > :first-child {
    margin-top: auto;
  }
`

export const MessageItemWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.setRight ? 'flex-end' : 'unset')};
  margin-right: 8px;
`

export const MessageItem = styled.div`
  color: ${props => (props.setRight ? 'white' : 'black')};
  margin-bottom: 12px;
  margin-left: 18px;
  padding: 18px 16px;
  max-width: ${props => (props.setRight ? '276px' : 'unset')};
  border-radius: ${props => (props.setRight ? '12px 12px 0px 12px' : '12px 12px 12px 0px')};
  margin-right: ${props => (props.setRight ? 'unset' : '8px')};
  background-color: ${props =>
    props.setRight ? props.theme.colors.primary1 : props.theme.colors.secondary1};
`

export const InputForm = styled.form``

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-right: 16px;
  background: white;
  border-top: 1px solid ${props => props.theme.colors.secondary2};
  border-radius: 0 0 12px 12px;

  ${props => props.theme.mediaQuery.md} {
    margin-top: auto;
  }
`

export const InputMessage = styled.input`
  border: none;
  padding: 16px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0 0 0 12px;
  width: 100%;
  font-size: 16px;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  line-height: 130%;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #4a5968;
  }
`

export const SendButtonWrapper = styled.div`
  margin-right: 16px;
  margin-top: 4px;
`
export const InputSendButton = styled(SendButton)``
