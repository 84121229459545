import styled from 'styled-components'

export const ActivatorButtonWrapper = styled.div`
  display: ${props => (props.shouldShowOnMobile ? 'block' : 'none')};
  position: fixed;
  z-index: 99999999;
  bottom: 88px;
  right: 32px;
  margin-top: 8px;
  cursor: pointer;

  ${props => props.theme.mediaQuery.lg} {
    display: block;
    bottom: 32px;
  }
`
