import {v1} from 'uuid'
import {getTour} from 'services/tourService'
import {getCheckObject, postNewGrantedPayment} from 'services/paymentService'
import {captureException} from 'services/errorService'
import {CHECKOUT} from 'consts.json'
import {TOUR_FULL} from 'routes.json'

export default class GrantByCodeService {
  constructor(props) {
    this.user = 'pageProps' in props && 'user' in props.pageProps ? props.pageProps.user : {}
    this.router = 'router' in props ? props.router : {}
    this.query = 'router' in props && 'query' in props.router ? props.router.query : {}
    this.unusedGrantCode = this.getCode()
    this.token = 'pageProps' in props && 'token' in props.pageProps ? props.pageProps.token : ''
  }

  getCode = () => {
    // If "grantByCode" is in querystring save it to localStorage
    if ('grantByCode' in this.query) {
      localStorage.setItem('grantByCode', this.query.grantByCode)
    }

    if (
      this.user &&
      'isLogged' in this.user &&
      this.user.isLogged &&
      'grantByCode' in localStorage
    ) {
      return localStorage.getItem('grantByCode')
    }

    return null
  }

  useCode = async () => {
    try {
      const getCheckObjectResponse = await getCheckObject(this.unusedGrantCode)
      const {
        grantUsed,
        grantsItemByCode,
        grantsItemId,
        grantsItemType,
        orderItem
      } = getCheckObjectResponse

      if (grantUsed) {
        localStorage.removeItem('grantByCode')
        return
      }

      if (grantsItemByCode && grantsItemId && grantsItemType) {
        const tour = await getTour(grantsItemId)
        const {title, slug, price} = tour

        const id = v1()
        const data = {
          cartId: id,
          transactionId: id,
          type: grantsItemType,
          currency: CHECKOUT.DEFAULT_CURRENCY,
          promoCode: null,
          userId: this.user.id,
          userName: `${this.user.firstName} ${this.user.lastName}`,
          userEmail: this.user.email,
          birthdayTimestamp: null,
          taxRefundCountry: null,
          taxRefundCity: null,
          taxRefundZip: null,
          taxRefundStreetAddress: null,
          platform: 'WEB',
          paymentGateway: {name: 'GRANTED'},
          items: [
            {
              itemId: grantsItemId,
              itemType: grantsItemType,
              title: title,
              itemPrice: 0,
              quantity: 1,
              institutionId: orderItem.institutionId || null,
              originPrice: price,
              promoCode: null,
              promoId: null,
              promotionDiscountAmount: null,
              promotionDiscountType: null,
              donationShareAmount: null,
              price: 0,
              isDonation: false,
              isFree: true,
              grantedByCode: this.unusedGrantCode,
              meta: {seoURL: slug}
            }
          ]
        }

        const payment = await postNewGrantedPayment(data, this.token)
        const {status} = payment

        if (status && status === 'APPROVED') {
          localStorage.removeItem('grantByCode')

          this.router.push(`${TOUR_FULL.link}/[slug]`, `${TOUR_FULL.link}/${slug}`)
        }
      }
    } catch (error) {
      captureException(error)
    }
  }
}
