import React, {Component} from 'react'
import {GEOLOCATION_DEFAULT, GEOLOCATION_STATUS, GEOLOCATION_OPTIONS} from 'consts.json'

export type Context = {
  geolocation: Object,
  geolocationLoaded: Boolean,
  registerConsumer?: Function,
  unregisterConsumer?: Function
}

export const defaulContext: Context = {
  geolocation: GEOLOCATION_DEFAULT,
  geolocationLoaded: false,
  registerConsumer: null,
  unregisterConsumer: null
}

export const CPGeolocationContext = React.createContext(defaulContext)

class CPGeolocationProvider extends Component {
  initialLoad = true
  watchId = null
  previousGeolocation = GEOLOCATION_DEFAULT
  started = false

  constructor(props) {
    super(props)
    this.state = {loaded: false, geolocation: GEOLOCATION_DEFAULT}
  }

  /*
   * We have register and unregister functions that
   * can be extended in future to do more, but at this point we need only
   * information that somebody wants location.
   */

  // eslint-disable-next-line no-unused-vars
  registerConsumer = consumer => {
    if (!this.started) {
      this.started = true
      this.fetchLocation()
    }
  }

  // eslint-disable-next-line no-unused-vars
  unregisterConsumer = consumer => {}

  async fetchLocation() {
    if (!process.browser) {
      return
    }
    try {
      const response = await fetch('/geoip')
      const json = await response.json()
      const {ll = [GEOLOCATION_DEFAULT.latitude, GEOLOCATION_DEFAULT.longitude]} = json

      const geolocation = {
        ...this.previousGeolocation,
        status: GEOLOCATION_STATUS.FALLBACK,
        permission: GEOLOCATION_STATUS.UNKNOWN,
        latitude: ll[0],
        longitude: ll[1],
        timestamp: new Date().getTime()
      }
      this.setGeolocation(geolocation)
    } catch (error) {
      this.setGeolocation(GEOLOCATION_DEFAULT)
      this.onError({code: 4, message: error})
    } finally {
      this.initialLoad = false
      this.watch()
    }
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchId)
  }

  watch = () => {
    this.watchId = navigator.geolocation.watchPosition(
      position => {
        this.initialLoad = false
        this.onSuccess(position)
      },
      error => {
        if (this.initialLoad) {
          this.setGeolocation(GEOLOCATION_DEFAULT)
        }
        this.initialLoad = false
        this.onError(error)
      },
      {
        enableHighAccuracy: GEOLOCATION_OPTIONS.ENABLE_HIGH_ACURACY,
        timeout: GEOLOCATION_OPTIONS.TIMEOUT,
        maximumAge: GEOLOCATION_OPTIONS.MAXIMUM_AGE,
        distanceFilter: GEOLOCATION_OPTIONS.DISTANCE_FILTER
      }
    )
  }

  onSuccess = position => {
    const geolocation = {
      ...this.previousGeolocation,
      status: GEOLOCATION_STATUS.SUCCESS,
      permission: GEOLOCATION_STATUS.PERMISSION_ALLOWED,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      altitude: position.coords.altitude,
      accuracy: position.coords.accuracy,
      timestamp: position.timestamp
    }

    // On each location update this event fires twice.
    // First with previous, and second time with new location.
    if (
      this.previousGeolocation.status !== geolocation.status ||
      this.previousGeolocation.latitude !== geolocation.latitude ||
      this.previousGeolocation.longitude !== geolocation.longitude
    ) {
      this.setGeolocation(geolocation)
    }
  }

  onError = error => {
    // eslint-disable-next-line no-console
    console.error('Geolocation error', error)
  }

  setGeolocation = geolocation => {
    this.previousGeolocation = geolocation
    this.setState({loaded: true, geolocation})
  }

  render() {
    const value = {
      geolocation: this.state.geolocation,
      geolocationLoaded: this.state.loaded,
      registerConsumer: this.registerConsumer,
      unregisterConsumer: this.unregisterConsumer
    }
    return (
      <CPGeolocationContext.Provider value={value}>
        {this.props.children}
      </CPGeolocationContext.Provider>
    )
  }
}

export default CPGeolocationProvider
