import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'

import {prepareBaseQuery} from '../'

const LIMIT_10 = 10
const LIMIT_20 = 20
const DEFAULT_OFFSET = 0

export const spotApi = createApi({
  reducerPath: 'spotApi',
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery('spot'),
  tagTypes: ['Spot'],
  endpoints: builder => ({
    getMySpots: builder.query({
      query: (args = {}) => ({
        url: `/my-spots?limit=${args?.limit ? args?.limit : LIMIT_10}&offset=${
          args?.offset ? args?.offset : DEFAULT_OFFSET
        }${args?.phrase ? '&phrase=' + args?.phrase : ''}`
      }),
      providesTags: result =>
        result
          ? [
              ...result.spots.map(({id}) => ({type: 'Spot', id})),
              {type: 'Spot', id: 'PARTIAL-LIST'}
            ]
          : [{type: 'Spot', id: 'PARTIAL-LIST'}]
    }),
    checkIfUsed: builder.query({
      query: id => ({
        url: `/check-if-used/${id}`
      }),
      transformResponse: response => response.used
    }),
    getByLocationAndCategories: builder.query({
      query: (args = {}) => ({
        url: `/by-location-and-categories/?limit=${args?.limit ? args?.limit : LIMIT_20}&offset=${
          args?.offset ? args?.offset : DEFAULT_OFFSET
        }${args?.countryId ? `&country_id=${args?.countryId}` : ''}${
          args?.regionId ? `&region_id=${args?.regionId}` : ''
        }${args?.cityId ? `&city_id=${args?.cityId}` : ''}${
          args?.categories ? `&categories=${args?.categories}` : ''
        }`
      }),
      transformResponse: response => ({total: response.total, spots: response.spots})
    }),
    getSpot: builder.query({
      query: ({slug, languageId}) => ({
        url: `/${slug}?lang=${languageId}`
      }),
      providesTags: (result, error, slug) => [{type: 'Spot', id: slug}],
      transformResponse: response => response.spot
    })
  })
})

export const {
  useGetMySpotsQuery,
  useLazyGetMySpotsQuery,
  useLazyCheckIfUsedQuery,
  useGetByLocationAndCategoriesQuery,
  useGetSpotQuery,
  // This an export for SSR
  util: {getRunningOperationPromises}
} = spotApi

export const {getByLocationAndCategories, getSpot} = spotApi.endpoints
