import styled from 'styled-components'

export const Wrapper = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: fit-content;
  margin-bottom: 12px;
  margin-left: 18px;
  padding: 18px 16px;
  border-radius: 12px 12px 12px 0px;
  background-color: ${props => props.theme.colors.secondary1};

  @keyframes jumpingAnimation {
    0 {
      transform: translate(0, 0);
    }
    16% {
      transform: translate(0, -15px);
    }
    33% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.secondary8};
  }

  .container .dot:nth-last-child(1) {
    animation: jumpingAnimation 1.8s 0.6s ease-in infinite;
  }
  .container .dot:nth-last-child(2) {
    animation: jumpingAnimation 1.8s 0.4s ease-in infinite;
  }
  .container .dot:nth-last-child(3) {
    animation: jumpingAnimation 1.8s 0.2s ease-in infinite;
  }
`

export const Container = styled.div`
  display: flex;
  gap: 6px;
  padding: 4px 0 4px 0;
`

export const Dot = styled.div``
