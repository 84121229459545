import {createApi} from '@reduxjs/toolkit/query/react'

import {prepareBaseQuery} from '../'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: prepareBaseQuery('user'),
  tagTypes: ['User'],
  endpoints: builder => ({
    getSubscriptionRestrictions: builder.query({
      query: () => ({url: '/subscription-restrictions'}),
      transformResponse: response => response.restrictions,
      providesTags: () => [{type: 'User', id: 'SUBSCRIPTION_RESTRICTIONS'}]
    })
  })
})

export const {useGetSubscriptionRestrictionsQuery} = userApi
