import {createApi} from '@reduxjs/toolkit/query/react'
import {HYDRATE} from 'next-redux-wrapper'

import {prepareBaseQuery} from '../'

export const homeApi = createApi({
  reducerPath: 'homeApi',
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery('home'),
  tagTypes: ['Home'],
  endpoints: builder => ({
    getHomeData: builder.query({
      query: (args = {}) => ({
        url: `/data?lang=${'en'}
        &experiences_limit=${args.experienceLimit}
        &experiences_offset=${args.experienceOffset}
        &guides_limit=${args.guidesLimit}
        &guides_offset=${args.guidesOffset}
        &attractions_limit=${args.attractionsLimit}
        &attractions_offset=${args.attractionsOffset}
        ${args.cityId ? '&cityId=' + args.cityId : ''}
        ${args.regionId ? '&regionId=' + args.regionId : ''}
        ${args.countryId ? '&countryId=' + args.countryId : ''}`
      }),
      providesTags: (result, error, {cityId = null, regiondId = null, countryId = null}) => [
        {type: 'Home', id: `${cityId}-${regiondId}-${countryId}`}
      ]
    })
  })
})

export const {
  useLazyGetHomeDataQuery,
  // This an export for SSR
  util: {getRunningOperationPromises}
} = homeApi

// Export endpoints for use in SSR
export const {getHomeData} = homeApi.endpoints
