import * as S from './Styled'

const WaitingMessageDots = ({isVisible}) => {
  return (
    <S.Wrapper isVisible={isVisible}>
      <S.Container className="container">
        <S.Dot className="dot" />
        <S.Dot className="dot" />
        <S.Dot className="dot" />
      </S.Container>
    </S.Wrapper>
  )
}

export default WaitingMessageDots
