import config from 'config'
import {createApi} from '@reduxjs/toolkit/query/react'
import {PRODUCT_TYPES} from 'consts.json'
import {prepareBaseQuery} from '../'

export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: prepareBaseQuery(`${config.API_TICKETING_SERVER_BASE}/template`),
  tagTypes: ['Template'],
  endpoints: builder => ({
    getTemplateByType: builder.query({
      query: ({id, type = PRODUCT_TYPES.TOUR}) => ({
        url: `by-type?itemId=${id}&itemType=${type}`
      }),
      transformResponse: response => response.templates
    })
  })
})

export const {useGetTemplateByTypeQuery} = templateApi
